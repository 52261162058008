<template>
  <b-card>
    <b-row>
      <b-col cols="4" class="ml-1">
        <v-select
          v-if="company.role.name !== 'Client'"
          class="selectExample mt-0 select-size-sm"
          v-model="client_id"
          :options="itemsOptions"
          label="Client"
          :getOptionLabel="(item) => item.client_name"
          :reduce="(item) => item"
          placeholder="Selecione o cliente"
        >
          <template v-slot:option="item">
            <div class="select-responsible">
              <div>
                {{ item.client_name }}
              </div>
            </div>
          </template>
        </v-select>
      </b-col>

      <vx-table :fields="fields" :items="items" type="training" />
    </b-row>
  </b-card>
</template>

<script>
import VxTable from '@/components/vx-table/VxTable.vue';
import { BCard, BRow, BCol } from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import vSelect from 'vue-select';

export default {
  components: {
    VxTable,
    BCard,
    vSelect,
    BRow,
    BCol,
    ToastificationContent,
  },
  data() {
    return {
      client_id: '',
      items: [],
      itemsOptions: [
        {
          client_id: 1,
          client_name: 'Todos',
          description: 'Todos',
        },
      ],
      fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
          sortDirection: 'desc',
          class: 'd-none text-center',
        },
        {
          key: 'index',
          label: 'Item',
          sortable: true,
          sortDirection: 'desc',
          class: 'text-center',
        },
        {
          key: 'description',
          label: 'Descrição',
          sortable: true,
          sortDirection: 'desc',
          class: 'text-center',
        },
        {
          key: 'client_name',
          label: 'Estabelecimento',
          sortable: true,
          sortDirection: 'desc',
          class: 'text-center',
        },
        {
          key: 'date',
          label: 'Data',
          sortable: true,
          sortDirection: 'asc',
          class: 'text-center',
        },
        {
          key: 'actions',
          label: 'Ações',
          class: 'text-center',
        },
      ],
    };
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },
  },

  watch: {
    client_id(val) {
      this.filteredItems(val.client_id);
    },
  },

  mounted() {
    this.getAllClientsOptions();
  },
  methods: {
    filteredItems(val) {
      if (val == 1) {
        this.items = [];
        this.getAllClientsOptions();
      } else {
        this.items = [];
        this.$store
          .dispatch('getAllTrainings', {
            client_id: val,
            company_id: this.company.id,
          })
          .then((resp) => {
            resp.map((item) => {
              let objectItems = {
                id: item.id,
                description: item.training.description,
                date: this.dateFormatter(
                  item.created_at,
                  'DD/MM/yyyy'
                ),
                client_name: item.client.name,
                client_id: item.client.id,
                path: item.training.path,
              };
              this.items.push(objectItems);
            });
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Não foi possível carregar os documentos',
                text: `${error}`,
                icon: 'XIcon',
                variant: 'danger',
              },
            });
          });
      }
    },
    getAllClientsOptions() {
      const arrayTemp = [
        {
          client_id: 1,
          client_name: 'Todos',
          description: 'Todos',
        },
      ];
      this.$store
        .dispatch('getAllClientTrainings')
        .then((resp) => {
          const arrayUniqueByKey = [
            ...new Map(resp.map((item) => [item['client_id'], item])).values(),
          ];
          arrayUniqueByKey.map((item) => {
            console.log(item)
            let objectItensOptions = {
              id: item.id,
              description: item.training.description,
              date: this.dateFormatter(
                item.created_at,
                'DD/MM/yyyy'
              ),
              client_name: item.client.name,
              client_id: item.client.id,
              path: item.training.path,
            };
            arrayTemp.push(objectItensOptions);
          });
          this.itemsOptions = arrayTemp;
          resp.map((item) => {
            let objectItens = {
              id: item.id,
              description: item.training.description,
              date: this.dateFormatter(
                item.created_at,
                'DD/MM/yyyy'
              ),
              client_name: item.client.name,
              client_id: item.client.id,
              path: item.training.path,

            };
            this.items.push(objectItens);
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Não foi possível carregar os documentos',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },
  },
};
</script>
<style scoped>
body {
  font-family: 'Source Sans Pro', 'Helvetica Neue', Arial, sans-serif;
  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
  -moz-text-size-adjust: none;
}

h1,
.muted {
  color: #2c3e5099;
}

h1 {
  font-size: 26px;
  font-weight: 600;
}

#app {
  max-width: 30em;
  margin: 1em auto;
}
</style>
